import "../scss/app.scss";
import Vue from "vue";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";

const app = new Vue({
  el: "#app",
  data: {
    toggleMenu: false,
  },
  mounted() {
    this.SwiperInit();
  },
  methods: {
    SwiperInit() {
      const swiper = new Swiper(".swiper-container", {
        speed: 4000,
        spaceBetween: 30,
        freeMode: true,
        width: 250,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
        loop: true,
      });
    },
  },
});
